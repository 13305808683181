import { useAppContext } from '@/context/Context';
import { useState } from 'react';



const HeaderTopFour = ({
  gapSpaceBetween,
  bgColor,
  flexDirection,
  btnText,
  btnClass,
  container,
}) =>  {
  const { toggle, setToggle } = useAppContext();

  const [topBarTimer, setTopBarTimer] = useState(false);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setTopBarTimer(true);
  //   }, 3000); // 3000 milliseconds = 3 seconds

  //   return () => clearTimeout(timer); // Clear the timer on component unmount
  // }, []);

  return (
    <>
      <div
        className={`rbt-header-top rbt-header-top-1 variation-height-50 ${gapSpaceBetween} pt-4 pb-3 d-none ${
          !toggle ? "d-none" : ""
        }`}
        style={{display: 'none'}}
      >
        <div className={`${container}`}>
          <div className={`rbt-header-sec align-items-center ${flexDirection}`}>
            <div className="rbt-header-sec-col rbt-header-left">
              <div className="rbt-header-content">
                {/* <div className="header-info">
                  <ul className="rbt-information-list">
                    <li>
                      <Link href={`Tel: +1 587-349-1110`}>
                        <i className="feather-phone"></i>+1 587-349-1110
                      </Link>
                    </li>
                  </ul>
                </div> */}
                {/* <div className="rbt-separator"></div> */}
                
              </div>
            </div>

            <div className="rbt-header-sec-col rbt-header-right">
              <div className="rbt-header-content">
                {/* <div className="header-info">
                  <ul className="rbt-secondary-menu">
                    <li>
                      <Link href="/my-account">My Account</Link>
                    </li>
                    <li>
                      <Link href="#">FAQ</Link>
                    </li>
                    <li>
                      <Link href="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link href="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link href="#">Terms & Condition</Link>
                    </li>
                  </ul>
                </div> */}
                {/* <div className="header-info" onClick={() => setToggle(!toggle)}>
                  <div className="header-right-btn d-flex">
                    <Link className={`rbt-btn ${btnClass}`} href="#">
                      <span data-text={`Subscribe Now`}>Subscribe Now</span>
                    </Link>
                  </div>
                </div> */}
                {/* <div className="rbt-separator"></div> */}
                {/* <div className="header-info" onClick={() => setToggle(!toggle)}>
                  <div className="header-right-btn d-flex">
                    <Link className={`rbt-btn ${btnClass}`} href="/contact" style={{background: 'white', color: 'black'}}>
                      {/* <span data-text={`${btnText}`}>{btnText}</span> 
                      <span data-text={`Ask Your Query`}>Ask Your Query</span>
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div> 
      </div>
    </>
  );
};

export default HeaderTopFour