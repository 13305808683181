import Link from "next/link";
import Separator from "../Common/Separator";

const CopyRight = () => {
  return (
    <>
      <Separator />
      <div className="copyright-area copyright-style-1 ptb--20">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
              <p className="rbt-link-hover text-center text-lg-center" style={{color: '#fff'}}>
                Copyright © 2024{" "}
                <Link href="/" style={{color: '#fff'}}>
                  GD College.
                </Link>{" "}
                All Rights Reserved
              </p>
            </div>
            {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <ul className="copyright-link rbt-link-hover justify-content-center justify-content-lg-end mt_sm--10 mt_md--10">
                <li>
                  <Link href="#" style={{color: '#fff'}}>Terms of Service</Link>
                </li>
                <li>
                  <Link href="/pages/privacy-policy" style={{color: '#fff'}}>Privacy Policy</Link>
                </li>
                {/* <li>
                  <Link href="#">Subscription</Link>
                </li> 
                {/* <li>
                  <Link href="#">Login & Register</Link>
                </li> *
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CopyRight;
